import React from "react";
import "./Certification.css";
import Icon from "./Icon/Icon";

const Certification = ({ data }) => {
  const {
    logoURL,
    Organisme,
    Titre,
    DateEmission,
    DateExpiration,
    URL,
    Identifiant,
  } = data;

  const { name: orgName, url: orgUrl } = Organisme;

  return (
    <div className="certification-card">
      <div className="certification-item">
        <div className="certification-header">
          {logoURL && (
            <Icon
              src={logoURL}
              alt={`${orgName} logo`}
              size="40px"
              className="certification-logo"
              style={{
                marginRight: "3%",
                alignSelf: "center",
              }}
              url={orgUrl}
            />
          )}
          <h3 className="certification-title" style={{ textAlign: "left" }}>
            {Titre}
          </h3>
        </div>

        <div className="certification-details">
          <p className="certification-org">Organisme: {orgName}</p>
          <p className="certification-date-emission">
            Date d'émission: {DateEmission}
          </p>

          {DateExpiration && (
            <p className="certification-date-expiration">
              Date d'expiration: {DateExpiration}
            </p>
          )}

          {Identifiant && <p className="certification-id">ID: {Identifiant}</p>}

          {URL && (
            <p className="certification-link">
              <a href={URL} target="_blank" rel="noopener noreferrer">
                Voir la certification
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Certification;
