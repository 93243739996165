import React from "react";
import CV from "./components/CV.js";

function App() {
  return (
    <>
      <CV></CV>
    </>
  );
}

export default App;
