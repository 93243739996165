import logo_dga from "../assets/logos/dga.jpeg";
import logo_itm from "../assets/logos/itm+.jpeg";
import logo_itmsol from "../assets/logos/itmsol.jpeg";
import logo_ministere from "../assets/logos/ministere.jpeg";
import logo_photowatt from "../assets/logos/photowatt.jpeg";
import logo_ranchcomputing from "../assets/logos/ranchcomputing.jpeg";

const experiences = [
  {
    "Nom de l'Entreprise/Organisation": "Ranch Computing",
    Logo: logo_ranchcomputing,
    "Titre du Poste": "Ingénieur logiciel",
    "Durée & Lieu": {
      Debut: "Octobre 2022",
      Fin: "Aujourd'hui",
      Lieu: "Paris, Île-de-France, France",
    },
    "Contexte/Mission":
      "Ranch Computing est un acteur majeur dans le domaine du rendu d'images de synthèse, figurant parmi les 20 plus importants Datacenters de rendu au monde. Depuis 2006, cette ferme de rendu française offre aux infographistes de tous horizons des serveurs de calcul puissants, garantissant ainsi la maîtrise des délais et des budgets durant la phase de rendu. J'ai été impliqué dans la conception et le développement d'un nouveau gestionnaire pour la ferme de rendu 3D, un système largement distribué, reposant sur des technologies modernes telles que golang, message-passing, et unit testing.",
    "Réalisation/Tâches": [
      "Développement logiciel: Conception et développement du nouveau gestionnaire de ferme de rendu 3D, en intégrant une étude approfondie du logiciel précédent écrit en BASIC.",
      "Environnement de développement: Mise en place d'une infrastructure de préproduction comprenant GitLab, Ansible, Nomad, Consul, Vault, Proxmox, Docker et Golang.",
      "Gestion du CIR: Élaboration et supervision des processus relatifs au Crédit d'Impôt Recherche, avec un accent particulier sur la rigueur, la communication et l'organisation.",
      "Pilotage technique: Encadrement et suivi technique pour la création d'un nouveau site web vitrine, collaboration avec un sous-traitant externe et mise en place de l'infrastructure nécessaire avec AWS (Cloudfront et EC2).",
      "CI/CD & Review: Conception de pipelines pour les tests, linting, packaging. Revue de code et conseils sur interfaces d'outils logiciels 3D avec Python, GitLab et CI/CD.",
      "Intégration CRM: Pilotage et suivi technique pour l'intégration du CRM interne avec HubSpot, en utilisant PHP (Laravel) basé sur l'analyse de la documentation et des besoins.",
    ],
    "Technologies/Environnement Technique": {
      Langages: ["Golang", "Python", "PHP (Laravel)", "BASIC"],
      "DevOps & Outils": [
        "GitLab",
        "Ansible",
        "Nomad",
        "Consul",
        "Vault",
        "Proxmox",
        "Docker",
        "CI/CD",
      ],
      Cloud: ["AWS (Cloudfront, EC2)"],
      "Gestion de projet": ["Redmine"],
    },
    "Compétences Développées": [
      "Travail en équipe",
      "Autonomie",
      "Initiative",
      "Capacités de proposition",
      "Gestion de projet",
      "Pilotage technique",
      "Revue de code",
      "Architecture logicielle",
      "Intégration CRM",
      "Mise en place d'environnements de développement",
    ],
  },

  {
    "Nom de l'Entreprise/Organisation": "Ministère de l'Intérieur",
    Logo: logo_ministere,

    "Titre du Poste": "Technical Lead",
    "Durée & Lieu": {
      Debut: "avril 2022",
      Fin: "septembre 2022",
      Durée: "6 mois",
      Lieu: "Issy-les-Moulineaux, Île-de-France, France",
    },
    "Contexte/Mission":
      "Il s’agit d’une mission au sein de la DGGN (Direction Générale de la Gendarmerie Nationale), dans le département ST(SI)² (Service des technologies et des systèmes d'information de la sécurité intérieure). SCRIBE est le nom du projet initial. Il s’agit d’une nouvelle interface qui permet de créer et de stocker des procès-verbaux, des plaintes mais aussi de les transférer à la justice. Le client du projet (MOA) est la DGPN (Direction Générale de la Police National). Aujourd’hui, un module est en phase de MCO (Maintien en Condition Opérationnelle) : THESEE, la plainte et le signalement en ligne pour les victimes d’e-escroqueries. Tech Lead sur la partie de MCO, en interface avec la MOE, j’ai également pris en charge des demandes de développements supplémentaires (en Scala) ayant été formulées par la suite. Équipes composées en moyenne de 3-4 personnes.",
    "Réalisation/Tâches": [
      "Suivi, analyse, gestion & correction des anomalies",
      "Préparation & documentation des mises en production",
      "Amélioration des documents de mise en production afin de minimiser les erreurs possibles",
      "Participation & application des choix techniques d’architecture (lien avec l’architecte projet)",
      "Etude, planification, conception, développement & tests (Scala)",
      "Encadrement & formation d’un collaborateur junior",
    ],
    Résultats: [
      "Réduction des erreurs de mise en production (effectuée par un autre département)",
      "Mise en production d’un premier développement effectuée avec succès",
    ],
    "Technologies/Environnement Technique": {
      "Technologies - Client & Backend": [
        "ElectronJS",
        "ReactJS",
        "OrientDB",
        "Scala",
        "SolR",
        "PostgreSQL",
      ],
      DevOps: [
        "Gitlab",
        "Jenkins",
        "Nexus Repository (SonaType)",
        "SonarQube",
        "Docker",
        "Ansible",
      ],
      OS: ["Debian"],
      "Gestion de projet": ["JIRA", "Confluence", "Kanban"],
    },
    "Compétences Développées": [
      "Maintien en Condition Opérationnelle",
      "Pilotage",
      "Encadrement technique",
      "Conception",
      "Développement",
      "Tests",
    ],
  },

  {
    "Nom de l'Entreprise/Organisation": "Spie batignolles ITM+",
    Logo: logo_itm,
    "Titre du Poste": "Innovation Technical Lead - Data Engineer",
    "Durée & Lieu": {
      Debut: "septembre 2019",
      Fin: "mars 2022",
      Durée: "2 ans 7 mois",
      Lieu: "Ville de Paris, Île-de-France, France",
    },
    "Contexte/Mission":
      "L’entreprise historique a souhaité se diversifier et notamment se développer sur les sujets IoT. J’ai travaillé en étroite collaboration avec le directeur de l’entité et la direction du groupe pour lancer la marque Spie batignolles itm+. Responsable de l’innovation, j’ai été amené à travailler sur de nombreux sujets. Équipes composées en moyenne de 3-4 personnes.",
    "Réalisation/Tâches": [
      "Développement et mise en place d'une interface d'agrégation de données d'engin de chantier pour la maintenance préventive/prédictive (opérationnel) et l'optimisation de l’utilisation des ressources (décisionnel)",
      "Développement d'un système de géopositionnnement de précision centimétrique temps réel sur la base du produit développé en stage",
      "Conception d'un système de géolocalisation sonore",
      "Création d'outils numériques internes d'optimisation des process",
      "Gestion des infrastructures, déploiement des sites et services web",
      "Rédaction de supports techniques pour les appels d’offre",
      "Conception & Développement de micro-services associés au recueil et traitement des données issues de capteurs IoT (dans le cadre du développement des SmartCities)",
      "Architecture & Développement d’une plateforme d’agglomération de données, d’analyse, monitoring et de visualisation",
      "Encadrement de stage, recrutement & formation de nouveaux collaborateurs",
    ],
    Résultats: [
      "Lancement de la marque, élargissement du catalogue & appels d’offres remportés",
      "Mise en production avec succès de la solution SaaS (développement de 2 ans)",
      "Croissance organique depuis mon arrivé (l’effectif qui stagnait jusqu’à présent, a doublé)",
    ],
    "Technologies/Environnement Technique": {
      "Langages de programmation": [
        "Golang",
        "Python",
        "C++/Qt",
        "Bash",
        "HTML",
        "CSS",
        "JS/ReactJS",
        "PHP",
      ],
      "Systèmes/Environnements": ["Ubuntu", "socket TCP", "websocket"],
      "Base de données": ["MariaDB", "Redis", "PostgreSQL"],
      Outils: ["Postman", "Git", "Scrum"],
    },
    "Compétences Développées": [
      "Gestion de projet",
      "Architecture",
      "Étude technique",
      "Pilotage",
      "Encadrement technique d’une petite équipe",
      "Conception",
      "Développement",
      "Intégration",
    ],
  },

  {
    "Nom de l'Entreprise/Organisation": "itmsol",
    Logo: logo_itmsol,
    "Titre du Poste": "Ingénieur recherche & développement",
    "Durée & Lieu": {
      Debut: "février 2019",
      Fin: "août 2019",
      Durée: "7 mois",
      Lieu: "Région de Paris, France",
    },
    "Contexte/Mission":
      "Spie batignolles itm+ est la marque (filiale) du groupe Spie batignolles (géant du BTP français) regroupant les activités de conception et déploiement de systèmes smart en faveur de la ville connectée au service de la qualité de vie, du bâtiment intelligent et d'un environnement durable. Le Projet de Fin d'Étude (PFE ingénieur) consistait à l’amélioration de la conception et le développement d’un prototype d’acquisition GNSS millimétrique couplé à un système de transmission. J’ai réalisé ce projet en autonomie avec le support ponctuel d’ingénieurs R&D et de topographes. Durant ce stage, j’ai été amené à travailler sur d’autres sujets en parallèle.",
    "Réalisation/Tâches": [
      "Reprise des précédents travaux (deux stages de recherche de topographe)",
      "Analyse du besoin, conception:",
      "Mécanique (choix de solution, CAO)",
      "Électronique, informatique (choix techniques : architecture & composants)",
      "Développement du prototype:",
      "Mécatronique (Gamme de fabrication, impression 3D, intégration de composants)",
      "Informatique (Intégration de codes, environnement linux, scripts d’automatisation)",
      "Tests & validation:",
      "Essais terrain & analyse des données",
      "Lancement du produit (POC):",
      "Communication client (présentation du produit, captation de nouveaux besoins)",
      "Essais client",
      "Développement d’une interface web de visualisation de données (BI, maintenance prédictive)",
      "Conception & développement de deux prototypes de capteurs",
      "Développement web mineur pour amélioration de l’ergonomie d’un outil SaaS",
    ],
    Résultats: [
      "Intégration du capteur sur chantier en interne",
      "Client intéressé pour le lancement de POC supplémentaires",
      "Libération de 30% du temps de travail de l’ingénieur maintenance",
    ],
    "Technologies/Environnement Technique": {
      "Langages de programmation": [
        "Python",
        "Matlab",
        "C",
        "Bash",
        "HTML",
        "CSS",
        "JS/JQuery",
        "PHP",
      ],
      "Systèmes/Environnements": ["Debian"],
      "Base de données": ["MySQL"],
      Outils: ["Creo"],
      Compétences: [
        "Analyse du besoin",
        "Conception",
        "Développement",
        "Gestion de projet",
      ],
    },
    "Compétences Développées": [],
  },

  {
    "Nom de l'Entreprise/Organisation":
      "DGA - Direction générale de l'armement",
    Logo: logo_dga,
    "Titre du Poste":
      "Assistant ingénieur - Sciences-informatique & traitement du signal",
    "Durée & Lieu": {
      Debut: "juin 2017",
      Fin: "août 2017",
      Durée: "3 mois",
      Lieu: "Bruz",
    },
    "Contexte/Mission":
      "Il s’agit d’un stage au sein de la Direction Générale de l’armement, dans le cadre de la validation de la deuxième année en école d'ingénieur, d'une durée de 10 semaines. La DGA-MI (Maitrise de l’Information) est l'expert technique du ministère des Armées pour les systèmes d'information et de communication. Le projet consistait à la création d’une nouvelle interface de génération de signaux de brouillage (GNSS). J’ai réalisé ce projet en autonomie avec le support ponctuel d’un ingénieur traitement du signal.",
    "Réalisation/Tâches": [
      "Identification/analyse du besoin métier et de la précédente interface existante",
      "Conception",
      "Développement",
      "Tests & validation",
    ],
    Résultats: [
      "Identification d’erreurs dans la précédente interface",
      "Interface livrée et utilisée par l’ensemble du département",
    ],
    "Technologies/Environnement Technique": {
      Outils: ["Matlab"],
      Domaines: [
        "traitement du signal",
        "Analyse du besoin",
        "Conception",
        "Développement",
        "Gestion de projet",
      ],
    },
    "Compétences Développées": [],
  },

  {
    "Nom de l'Entreprise/Organisation": "EDF ENR PWT (Photowatt)",
    Logo: logo_photowatt,
    "Titre du Poste": "Technicien méthode et industrialisation",
    "Durée & Lieu": {
      Debut: "avril 2015",
      Fin: "août 2015",
      Durée: "5 mois",
      Lieu: "Bourgoin-Jallieu (38), France",
    },
    "Contexte/Mission":
      "J'ai travaillé chez EDF ENR PWT (Photowatt) en deux rôles consécutifs : d'abord en tant que technicien stagiaire pour valider mon DUT GMP pendant 10 semaines, puis comme technicien en mission intérimaire pendant 4 semaines.",
    "Réalisation/Tâches": [
      "Analyse des besoins industriels.",
      "Études ergonomiques pour l'optimisation des postes de travail.",
      "Conception et mise en œuvre de solutions techniques.",
      "Gestion des demandes d'offres, installation et suivi de projets.",
      "Travail collaboratif en équipe tout en faisant preuve d'autonomie et de polyvalence.",
    ],
    "Compétences Développées": [
      "Anticipation",
      "Gestion de projets",
      "Travail en équipe",
      "Relations humaines",
      "Autonomie",
      "Polyvalence",
    ],
  },
];

export default experiences;
