import React from "react";
import "./Information.css";

// import education from "../assets/icons/education.svg";
// import website from "../assets/icons/website.svg";
// import technologies from "../assets/icons/technologies.svg";
// import hackathon from "../assets/icons/hackathon.svg";
// import birthday from "../assets/icons/birthday.svg";
// import language from "../assets/icons/language.svg";
// import skills from "../assets/icons/skills.svg";
// import marital_status from "../assets/icons/marital_status.svg";
// import experience_increase from "../assets/icons/experience_increase.svg";
// import result from "../assets/icons/result.svg";
// import id_badge from "../assets/icons/id_badge.svg";
// import experience_goal from "../assets/icons/experience_goal.svg";
// import localisation_simple from "../assets/icons/localisation_simple.svg";
// import nationality from "../assets/icons/nationality.svg";
// import position from "../assets/icons/position.svg";
// import skills_puzzle from "../assets/icons/skills_puzzle.svg";
// import certification_hard from "../assets/icons/certification_hard.svg";
// import smartphone from "../assets/icons/smartphone.svg";
// import birthday_hard from "../assets/icons/birthday_hard.svg";
// import contest from "../assets/icons/contest.svg";
// import phone from "../assets/icons/phone.svg";
// import marital_status_hard from "../assets/icons/marital_status_hard.svg";
// import abilities from "../assets/icons/abilities.svg";
// import localisation from "../assets/icons/localisation.svg";
// import production_cooperation from "../assets/icons/production_cooperation.svg";
// import job from "../assets/icons/job.svg";
// import web from "../assets/icons/web.svg";
// import company_simple from "../assets/icons/company_simple.svg";
// import gear_3d from "../assets/icons/gear_3d.svg";
// import gear from "../assets/icons/gear.svg";
// import date_calendar from "../assets/icons/date_calendar.svg";
// import production from "../assets/icons/production.svg";
// import email from "../assets/icons/email.svg";
// import gear_simple from "../assets/icons/gear_simple.svg";
// import driving_licence from "../assets/icons/driving_licence.svg";
// import abilities_hard from "../assets/icons/abilities_hard.svg";
// import company from "../assets/icons/company.svg";
// import certificate_paper from "../assets/icons/certificate_paper.svg";
// import experiences from "../assets/icons/experiences.svg";
// import car from "../assets/icons/car.svg";
// import certification from "../assets/icons/certification.svg";
// import technology from "../assets/icons/technology.svg";
// import email_hard from "../assets/icons/email_hard.svg";

import { ReactComponent as EducationIcon } from "../assets/icons/education.svg";
import { ReactComponent as WebsiteIcon } from "../assets/icons/website.svg";
import { ReactComponent as TechnologiesIcon } from "../assets/icons/technologies.svg";
import { ReactComponent as HackathonIcon } from "../assets/icons/hackathon.svg";
import { ReactComponent as BirthdayIcon } from "../assets/icons/birthday.svg";
import { ReactComponent as LanguageIcon } from "../assets/icons/language.svg";
import { ReactComponent as SkillsIcon } from "../assets/icons/skills.svg";
import { ReactComponent as MaritalStatusIcon } from "../assets/icons/marital_status.svg";
import { ReactComponent as ExperienceIncreaseIcon } from "../assets/icons/experience_increase.svg";
import { ReactComponent as ResultIcon } from "../assets/icons/result.svg";
import { ReactComponent as IdBadgeIcon } from "../assets/icons/id_badge.svg";
import { ReactComponent as ExperienceGoalIcon } from "../assets/icons/experience_goal.svg";
import { ReactComponent as LocalisationSimpleIcon } from "../assets/icons/localisation_simple.svg";
import { ReactComponent as NationalityIcon } from "../assets/icons/nationality.svg";
import { ReactComponent as PositionIcon } from "../assets/icons/position.svg";
import { ReactComponent as SkillsPuzzleIcon } from "../assets/icons/skills_puzzle.svg";
import { ReactComponent as CertificationHardIcon } from "../assets/icons/certification_hard.svg";
import { ReactComponent as SmartphoneIcon } from "../assets/icons/smartphone.svg";
import { ReactComponent as BirthdayHardIcon } from "../assets/icons/birthday_hard.svg";
import { ReactComponent as ContestIcon } from "../assets/icons/contest.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/phone.svg";
import { ReactComponent as MaritalStatusHardIcon } from "../assets/icons/marital_status_hard.svg";
import { ReactComponent as AbilitiesIcon } from "../assets/icons/abilities.svg";
import { ReactComponent as LocalisationIcon } from "../assets/icons/localisation.svg";
import { ReactComponent as ProductionCooperationIcon } from "../assets/icons/production_cooperation.svg";
import { ReactComponent as JobIcon } from "../assets/icons/job.svg";
import { ReactComponent as WebIcon } from "../assets/icons/web.svg";
import { ReactComponent as CompanySimpleIcon } from "../assets/icons/company_simple.svg";
import { ReactComponent as Gear3dIcon } from "../assets/icons/gear_3d.svg";
import { ReactComponent as GearIcon } from "../assets/icons/gear.svg";
import { ReactComponent as DateCalendarIcon } from "../assets/icons/date_calendar.svg";
import { ReactComponent as ProductionIcon } from "../assets/icons/production.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import { ReactComponent as GearSimpleIcon } from "../assets/icons/gear_simple.svg";
import { ReactComponent as DrivingLicenceIcon } from "../assets/icons/driving_licence.svg";
import { ReactComponent as AbilitiesHardIcon } from "../assets/icons/abilities_hard.svg";
import { ReactComponent as CompanyIcon } from "../assets/icons/company.svg";
import { ReactComponent as CertificatePaperIcon } from "../assets/icons/certificate_paper.svg";
import { ReactComponent as ExperiencesIcon } from "../assets/icons/experiences.svg";
import { ReactComponent as CarIcon } from "../assets/icons/car.svg";
import { ReactComponent as CertificationIcon } from "../assets/icons/certification.svg";
import { ReactComponent as TechnologyIcon } from "../assets/icons/technology.svg";
import { ReactComponent as EmailHardIcon } from "../assets/icons/email_hard.svg";

import Icon from "./Icon/Icon";

const Information = ({ data }) => {
  const {
    "Informations Personnelles": personalInfo,
    Contact,
    Permis,
    Langues,
    Introduction,
  } = data;

  const defaultIconSize = "30px";

  return (
    <>
      <div className="information-container">
        <div className="information-columns">
          <div className="personal-section">
            <Icon
              src={personalInfo.Photo}
              alt={`${personalInfo.Prénom} ${personalInfo.Nom}`}
              size="150px"
              className="profile-photo"
              style={{
                alignSelf: "center",
              }}
            />
            <h3
              className="name"
              style={{
                margin: "3px",
                alignSelf: "center",
              }}
            >
              {personalInfo.Prénom}
            </h3>
            <h3
              className="lastname"
              style={{
                margin: "3px",
                alignSelf: "center",
              }}
            >
              {personalInfo.Nom}
            </h3>
          </div>

          <div className="contact-section">
            <p>
              <Icon
                icon={CarIcon}
                alt="driving_licence"
                size={defaultIconSize}
                className="test-icon"
              />
              {Permis.Conduire}
            </p>
            <p>
              <Icon
                icon={BirthdayHardIcon}
                size={defaultIconSize}
                alt="birthday"
              />
              {personalInfo["Année de Naissance"]}
            </p>
            <p>
              <Icon
                icon={NationalityIcon}
                alt="nationality"
                size={defaultIconSize}
              />
              {personalInfo.Nationalité}
            </p>
            <p>
              <Icon
                icon={MaritalStatusHardIcon}
                alt="marital_status"
                size={defaultIconSize}
              />
              {personalInfo["État Civil"]}
            </p>
            <p>
              <Icon
                icon={PositionIcon}
                alt="localisation"
                size={defaultIconSize}
              />
              {Contact.Adresse.Rue ? Contact.Adresse.Rue + ", " : ""}
              {Contact.Adresse["Code Postal"]
                ? Contact.Adresse["Code Postal"] + ", "
                : ""}
              {Contact.Adresse.Ville}, {Contact.Adresse.Pays}
            </p>
          </div>

          <div className="skills-section">
            <p>
              <Icon
                icon={PhoneIcon}
                alt="phone"
                url={"tel:" + Contact.Téléphone.replaceAll(" ", "")}
                size={defaultIconSize}
              />
              {Contact.Téléphone}
            </p>
            <p>
              <Icon
                icon={EmailHardIcon}
                alt="email"
                url={`mailto:${Contact["Adresse Électronique"]}`}
                size={defaultIconSize}
              />
              <a href={`mailto:${Contact["Adresse Électronique"]}`}>
                {Contact["Adresse Électronique"]}
              </a>
            </p>
            <p>
              <Icon
                icon={WebIcon}
                alt="website"
                url={Contact["Site Internet"]}
                size={defaultIconSize}
              />
              <a href={Contact["Site Internet"]}>
                {Contact["Site Internet"].replaceAll("https://", "")}
              </a>
            </p>
            <>
              {Contact.Réseaux &&
                Object.entries(Contact.Réseaux).map(([key, { logo, url }]) =>
                  logo && url ? (
                    <p key={key}>
                      <Icon
                        src={logo}
                        alt={key}
                        url={url}
                        size={defaultIconSize}
                      />
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {key}
                      </a>
                    </p>
                  ) : null
                )}
            </>
          </div>

          <div
            className="language-section"
            style={{
              alignSelf: "center",
            }}
          >
            <>
              {Langues &&
                Object.entries(Langues).map(([key, value]) => (
                  <p key={key}>
                    <Icon
                      src={value.flag}
                      alt={key}
                      size={defaultIconSize}
                      bgColor=""
                    />
                    {value.desc}
                  </p>
                ))}
            </>
          </div>
        </div>
      </div>
      <div className="introduction">
        <p
          style={{
            marginRight: "0",
          }}
        >
          {Introduction}
        </p>
      </div>
    </>
  );
};

export default Information;
