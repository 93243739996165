import React from "react";
import "./Formation.css";
import Icon from "./Icon/Icon";

const Formation = ({ data }) => {
  const {
    logoURL,
    Organisme,
    "Intitulé de la Formation/Diplôme": Intitule,
    "Durée & Lieu": DureeLieu,
    Description,
    SubDescription,
  } = data;
  const { name: orgName, url: orgUrl } = Organisme;

  return (
    <div className="formation-card">
      <div className="formation-item">
        <div className="formation-header">
          {logoURL && (
            <Icon
              src={logoURL}
              alt={`${orgName} logo`}
              url={orgUrl}
              size="50px"
              className="formation-logo"
              style={{ marginRight: "3%", alignSelf: "center" }}
              setDefaultMargin={false}
            />
          )}
          <h3 className="formation-title">{Intitule}</h3>
        </div>
        <p className="formation-duration">
          {DureeLieu.Debut} - {DureeLieu.Fin}, {DureeLieu.Lieu}
        </p>

        {Description && <p className="formation-description">{Description}</p>}

        {SubDescription &&
          Object.entries(SubDescription).map(([key, subd]) => (
            <div className="formation-results" key={key}>
              <h4>{key}</h4>
              <ul>
                {subd && subd.map((res, index) => <li key={index}>{res}</li>)}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Formation;
