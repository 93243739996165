import logo_codingame from "../assets/logos/codingame.jpeg";
import logo_codebashing from "../assets/logos/codebashing.jpeg";

const certifications = [
  {
    Organisme: { name: "CodinGame", url: "https://www.codingame.com/" },
    Titre: "Certification CodinGame - Python 3",
    DateEmission: "janv. 2022",
    Identifiant: "VHhry8bB-XwooQ7omajnTg",
    URL: "https://www.codingame.com/certification/VHhry8bB-XwooQ7omajnTg",
    logoURL: logo_codingame,
  },
  {
    Organisme: { name: "CodinGame", url: "https://www.codingame.com/" },
    Titre: "Certification CodinGame - Golang",
    DateEmission: "déc. 2021",
    Identifiant: "k8wpl8v6uHMs9QjdZmOQqw",
    URL: "https://www.codingame.com/certification/k8wpl8v6uHMs9QjdZmOQqw",
    logoURL: logo_codingame,
  },
  {
    Organisme: { name: "CodinGame", url: "https://www.codingame.com/" },
    Titre: "Certification CodinGame - JavaScript",
    DateEmission: "déc. 2021",
    Identifiant: "ytQiRbUJ3Pghf3aIJZvy2Q",
    URL: "https://www.codingame.com/certification/ytQiRbUJ3Pghf3aIJZvy2Q",
    logoURL: logo_codingame,
  },
  {
    Organisme: { name: "CodinGame", url: "https://www.codingame.com/" },
    Titre: "Certification CodinGame - PHP",
    DateEmission: "déc. 2021",
    Identifiant: "-Y6tlg11Qd2ynylFigi_AA",
    URL: "https://www.codingame.com/certification/-Y6tlg11Qd2ynylFigi_AA",
    logoURL: logo_codingame,
  },
  {
    Organisme: { name: "CODEBASHING LTD", url: "https://www.codebashing.com/" },
    Titre: "Application Security and Secure Coding Training",
    DateEmission: "juin 2022",
    DateExpiration: "juin 2023",
    Identifiant: "25d199fa872560e4e10ad7b318525c5f4c24392d",
    URL: "",
    logoURL: logo_codebashing,
  },
];

export default certifications;
