import React from "react";

const Icon = ({
  icon: IconSvg = null, // Remplacer 'src' par 'icon'
  src = "",
  alt = "",
  url = "",
  size = "100px",
  bgColor = "var(--icon-bg-color)",
  fillColor = "var(--icon-fill-color)",
  border = "none",
  className = "",
  style = {},
  setDefaultMargin = true,
}) => {
  // Incorrect
  if ((!IconSvg && src === "") || alt === "") {
    console.error("Icon should contains an SVG component and alt.");
    return <></>;
  }

  const iconStyles = {
    width: size,
    height: size,
    backgroundColor: bgColor,
    fill: fillColor,
    borderRadius: "50%",
    margin: setDefaultMargin ? `calc(${size} * 0.1)` : "0",
    border: border,
  };

  let graphicElement;

  if (src !== "") {
    const imgElement = (
      <img src={src} alt={alt} className={className} style={iconStyles}></img>
    );
    graphicElement = imgElement;
  } else {
    const iconElement = (
      <IconSvg style={iconStyles} className={className} aria-label={alt} />
    );
    graphicElement = iconElement;
  }

  // link
  if (url === "") {
    return <span style={{ ...style }}>{graphicElement}</span>;
  } else {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ ...style }}
      >
        {graphicElement}
      </a>
    );
  }
};

export default Icon;
