const hackathons = [
  {
    "Nom de l'Événement": "HackTogether#2 - Prix du jury",
    Lien: "https://ense3.grenoble-inp.fr/fr/l-ecole/hackathon-hacktogether-2",
    "Informations événement": {
      Date: "Hackathon de 48h",
      Lieu: "Grenoble",
      Intervenants: "Grenoble INP ENSE3 & EDF",
    },
    Description:
      "Hackathon axé sur la problématique de la sécurité. Participation au sein d'une équipe pluridisciplinaire de 5 personnes sur le sujet intitulé \"ENQUÊTE DANS UNE CENTRALE HYDROÉLECTRIQUE\". Une dérive thermique est observée sur le métal de l'arbre (supportant 300 t, notamment l'aube de turbine et l'alternateur). Le métal utilisé perd de son efficacité ou fond au-delà de 200°C, avec des protections activées à 95°C.",
    "Résultats/Acquis": [
      "Identifier la cause de la dérive thermique d'une centrale hydroélectrique en utilisant l'intelligence artificielle.",
      "Data engineering/science: Nettoyage des données, mise en œuvre d'algorithmes de machine learning, tests et validations, identifications.",
      "Travail sur l'interopérabilité, la polyvalence, la synergie et la créativité.",
    ],

    "Technologies/Environnement": ["Matlab", "MySQL", "Lasso Regularization"],
  },
  {
    "Nom de l'Événement": "Hackathon sur l'Éco-coding",
    Lien: "https://www.isep.fr/wp-content/uploads/2022/09/cp-hackathon-green-code.pdf",
    "Informations événement": {
      Date: "Hackathon de 24h",
      Lieu: "Paris",
      Intervenants: "SAGEMCOM, 3DS OUTSCALE et ISEP",
    },
    Description:
      "Face aux conséquences environnementales du développement numérique, cet événement visait à sensibiliser aux impacts du logiciel sur l'environnement. La question centrale posée était : Comment rendre un programme informatique écrit en Python moins énergivore tout en assurant son bon fonctionnement ?",
    "Résultats/Acquis": [
      "Développer et optimiser des programmes pour qu'ils soient éco-responsables, en mettant l'accent sur la consommation d'énergie.",
      "Développement et Optimisation: Utilisation de Terraform, Docker, Golang, Python, C, et gestion de VM en SSH pour répondre aux défis.",
      "Collaboration avec une équipe pour concevoir des solutions innovantes.",
      "Exploitation de l'infrastructure Cloud de 3DS OUTSCALE pour mettre en œuvre nos solutions.",
    ],
    "Technologies/Environnement": [
      "Terraform",
      "Docker",
      "Golang",
      "Python",
      "C",
      "3DS OUTSCALE",
    ],
  },
];

export default hackathons;
