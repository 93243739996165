import React from "react";
import "./Experience.css";
import Icon from "./Icon/Icon";

const Experience = ({ data }) => {
  const {
    Logo,
    "Nom de l'Entreprise/Organisation": CompanyName,
    "Titre du Poste": PositionTitle,
    "Durée & Lieu": DurationAndPlace,
    "Contexte/Mission": ContextMission,
    "Réalisation/Tâches": Tasks,
    Résultats: Results,
    "Technologies/Environnement Technique": Technologies,
    "Compétences Développées": DevelopedSkills,
  } = data;

  return (
    <div className="experience-card">
      <div className="experience-item">
        <div className="experience-header">
          {Logo && (
            <Icon
              src={Logo}
              alt={`${CompanyName} logo`}
              size="40px"
              className="experience-logo"
              style={{
                marginRight: "3%",
                alignSelf: "center",
              }}
            />
          )}
          <h3 className="experience-title" style={{ textAlign: "left" }}>
            {PositionTitle}
          </h3>
        </div>

        <div className="experience-details">
          <p className="experience-company">Entreprise: {CompanyName}</p>
          <p className="experience-duration">
            {DurationAndPlace.Debut} - {DurationAndPlace.Fin},{" "}
            {DurationAndPlace.Lieu}
          </p>
          <p className="experience-context">{ContextMission}</p>

          <div className="experience-grid">
            {Tasks && Tasks.length > 0 && (
              <div className="experience-subcard">
                <h4>Réalisation/Tâches</h4>
                <ul>
                  {Tasks &&
                    Tasks.map((task, index) => <li key={index}>{task}</li>)}
                </ul>
              </div>
            )}

            {Results && Results.length > 0 && (
              <div className="experience-subcard">
                <h4>Résultats</h4>
                <ul>
                  {Results &&
                    Results.map((result, index) => (
                      <li key={index}>{result}</li>
                    ))}
                </ul>
              </div>
            )}

            {Technologies && Object.entries(Technologies).length > 0 && (
              <div className="experience-subcard">
                <h4>Technologies/Environnement Technique</h4>
                <ul
                  style={{
                    textAlign: "left",
                  }}
                >
                  {Technologies &&
                    Object.entries(Technologies).map(([domain, technos]) => (
                      <li key={domain}>
                        {/* {key}: {value.join(", ")} */}
                        {domain}
                        <div>
                          {technos.map((techno, index) => (
                            <span
                              key={index}
                              style={{
                                display: "inline-block",
                                backgroundColor: "var(--secondary-bg-color)",
                                margin: "3px",
                                padding: "2px",
                                borderRadius: "3px",
                                fontWeight: "501",
                              }}
                            >
                              {techno}
                            </span>
                          ))}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {DevelopedSkills && DevelopedSkills.length > 0 && (
              <div className="experience-subcard">
                <h4>Compétences Développées</h4>

                <div>
                  {DevelopedSkills &&
                    DevelopedSkills.length > 0 &&
                    DevelopedSkills.map((techno, index) => (
                      <p
                        key={index}
                        style={{
                          display: "inline-block",
                          backgroundColor: "var(--secondary-bg-color)",
                          margin: "3px",
                          padding: "2px",
                          borderRadius: "3px",
                          fontWeight: "501",
                        }}
                      >
                        {techno}
                      </p>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
