import React from "react";
import "./Hackathon.css";

const Hackathon = ({ data }) => {
  const {
    "Nom de l'Événement": eventName,
    "Informations événement": {
      Date: eventDate,
      Lieu: eventLocation,
      Intervenants: Intervenants,
    },
    Description,
    Rôle,
    "Résultats/Acquis": results,
    "Technologies/Environnement": technologies,
  } = data;

  return (
    <div className="hackathon-card">
      <div className="hackathon-item">
        <h3 className="hackathon-title">{eventName}</h3>
        <p className="hackathon-date-location">
          {eventDate} à {eventLocation} - {Intervenants}
        </p>
        <p className="hackathon-description">{Description}</p>

        <div className="hackathon-details">
          {results && results.length > 0 && (
            <div className="hackathon-results">
              <h4>Résultats/Acquis:</h4>
              <ul>
                {results &&
                  results.map((result, index) => <li key={index}>{result}</li>)}
              </ul>
            </div>
          )}

          {technologies && technologies.length > 0 && (
            <div className="hackathon-tech">
              <h4>Technologies utilisées:</h4>
              <ul>
                {technologies &&
                  technologies.map((tech, index) => (
                    <li key={index}>{tech}</li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hackathon;
