import photoProfil from "../assets/profil-512.jpeg";

import logo_linkedin from "../assets/logos/linkedin.jpeg";
import logo_codingame from "../assets/logos/codingame.jpeg";

import flag_fr from "../assets/flags/france.png";
import flag_en from "../assets/flags/english_uk.png";
import flag_de from "../assets/flags/germany.png";

const informations = {
  "Informations Personnelles": {
    Prénom: "Romain",
    Nom: "WALCH--GUINEBERT",
    "Année de Naissance": "1994",
    Nationalité: "Français",
    "État Civil": "Pacsé",
    Photo: photoProfil,
  },
  Contact: {
    Adresse: {
      Rue: "",
      "Code Postal": "75011",
      Ville: "Paris",
      Pays: "France",
    },
    Téléphone: "+33 61 245 22 87",
    "Adresse Électronique": "contact@r-wg.it",
    "Site Internet": "https://r-wg.it",
    Réseaux: {
      LinkedIn: {
        logo: logo_linkedin,
        url: "https://www.linkedin.com/in/romain-walch-guinebert/",
      },
      CodinGame: {
        logo: logo_codingame,
        url: "https://www.codingame.com/profile/138b350c94ed46ff2a2da46b09bdc9dc9734814",
      },
      GitHub: {
        logo: "",
        url: "",
      },
      Twitter: {
        logo: "",
        url: "",
      },
    },
  },
  Permis: {
    Conduire: "B",
  },
  Langues: {
    // fr: {
    //   name: "Français",
    //   flag: flag_fr,
    //   desc: "Langue maternelle",
    // },
    en: {
      name: "Anglais",
      flag: flag_en,
      desc: "B2 - Intermédiaire+",
    },
    de: {
      name: "Allemand",
      flag: flag_de,
      desc: "A2 - Élémentaire+",
    },
  },
  Introduction:
    "Ingénieur logiciel expérimenté et pluridisciplinaire, je maîtrise une gamme étendue de langages de programmation et technologies. Passionné par l'innovation, j'ai constamment soif d'apprendre et je recherche activement des projets avant-gardistes où appliquer mes compétences. Mon engagement se reflète aussi hors travail, où je me distingue en algorithmie et explore avec enthousiasme le monde du DIY et de la mécatronique.",
};

export default informations;
