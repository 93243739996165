import React from "react";

import experiences from "../resources/fr-experiences.js";
import formations from "../resources/fr-formations.js";
import hackathons from "../resources/fr-hackathons.js";
import informations from "../resources/fr-informations.js";
import certifications from "../resources/fr-certifications.js";

import Information from "./Information";
import Hackathon from "./Hackathon";
import Formation from "./Formation";
import Experience from "./Experience";
import Certification from "./Certification";

import "./CV.css";

const CV = () => {
  const renderSection = (title, classPrefix, dataArray, Component) => (
    <>
      {dataArray && dataArray.length > 0 && (
        <>
          <div
            className={classPrefix + "-section"}
            style={{
              marginTop: "20px",
            }}
          >
            <h2
              style={{
                marginLeft: "20px",
              }}
            >
              {title}
            </h2>
            <div className={classPrefix + "-container"}>
              {dataArray.map((dataItem, index) => (
                <Component key={index} data={dataItem} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <div className="cv">
      {/* Information */}
      <Information data={informations} />

      {/* Expériences professionnelles */}
      {renderSection(
        "Expériences Professionnelles",
        "experiences",
        experiences,
        Experience
      )}

      {/* Formations */}
      {renderSection("Formations", "formations", formations, Formation)}

      {/* Hackathons */}
      {renderSection("Hackathons", "hackathons", hackathons, Hackathon)}

      {/* Certifications */}
      {renderSection(
        "Certifications",
        "certifications",
        certifications,
        Certification
      )}
    </div>
  );
};

export default CV;
