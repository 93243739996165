import logo_oiselet from "../assets/logos/oiselet.jpeg";
import logo_lachenal from "../assets/logos/lachenal.jpeg";
import logo_uga from "../assets/logos/uga.jpeg";
import logo_ense3 from "../assets/logos/ense3.jpeg";
import logo_phelma from "../assets/logos/phelma.jpeg";

const formations = [
  {
    Organisme: {
      name: "Grenoble INP - Phelma",
      url: "https://phelma.grenoble-inp.fr/",
    },

    logoURL: logo_phelma,
    "Intitulé de la Formation/Diplôme":
      "Spécialisation en Signal Image Communication et Multimédia",
    "Durée & Lieu": {
      Debut: "2016",
      Fin: "2019",
      Durée: "3 ans",
      Lieu: "Grenoble, France",
    },
    Description: "",
    SubDescription: {
      Spécialités: [
        "Traitement du signal, électronique, informatique, détection, estimation, apprentissage statistique, traitement du signal en temps réel, compression, indexation.",
      ],
    },
  },
  {
    Organisme: {
      name: "Grenoble INP - ENSE3",
      url: "https://ense3.grenoble-inp.fr/",
    },
    logoURL: logo_ense3,
    "Intitulé de la Formation/Diplôme":
      "Diplôme d'Ingénieur en Traitement du Signal, Électronique et Informatique",
    "Durée & Lieu": {
      Debut: "2015",
      Fin: "2019",
      Durée: "4 ans",
      Lieu: "Grenoble, France",
    },
    Description: "Première année d'ingénierie générale",
    SubDescription: {
      Association: [
        "Trésorier associatif, Bureau Des Arts (ENSE3 - Grenoble INP), 25 membres.",
      ],
    },
  },
  {
    Organisme: {
      name: "Université Grenoble Alpes",
      url: "https://www.univ-grenoble-alpes.fr/",
    },
    logoURL: logo_uga,
    "Intitulé de la Formation/Diplôme":
      "Diplôme Universitaire de Technologie en Génie Mécanique et Productique",
    "Durée & Lieu": {
      Debut: "2013",
      Fin: "2015",
      Durée: "2 ans",
      Lieu: "Grenoble, France",
    },
    Description: "",
    SubDescription: {
      Niveau: ["Validé - Major de promotion"],
    },
  },
  {
    Organisme: {
      name: "Lycée Louis Lachenal",
      url: "https://www.lycee-louis-lachenal.fr/",
    },
    logoURL: logo_lachenal,
    "Intitulé de la Formation/Diplôme":
      "Classe Préparatoire aux Grandes Écoles (CPGE) Scientifique - PTSI",
    "Durée & Lieu": {
      Debut: "2012",
      Fin: "2013",
      Durée: "1 an",
      Lieu: "Argonay, France",
    },
    Description: "",
    SubDescription: {
      Mention: ["Bien"],
    },
  },
  {
    Organisme: { name: "Lycée l'Oiselet", url: "https://lycee-oiselet.fr/" },
    logoURL: logo_oiselet,
    "Intitulé de la Formation/Diplôme":
      "Baccalauréat Scientifique - Sciences de l'Ingénieur",
    "Durée & Lieu": {
      Debut: "2012",
      Fin: "2012",
      Durée: "1 an",
      Lieu: "France",
    },
    Description: "",
    SubDescription: {
      Activités: [
        "Développement d'un site web et d'outils de gestion (PHP, HTML, Visual Basic)",
      ],
    },
  },
];

export default formations;
